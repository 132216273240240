import type { PropsWithChildren } from 'react';
import { translate } from '@ma-js-common/translate';
import { Footer, Navbar } from '@ma-react/components';
import * as Sentry from '@sentry/nextjs';
import { ThorEventGA4Interface, useThorEvent } from '@sl/react-thor';

import type { GaPageData } from '@/entities/types/Ga';
import type User from '@/entities/types/User';
import type UserProType from '@/entities/types/UserProType';
import ShowcaseBanner from '@/front/components/ShowcaseBanner';
import DefaultLayout from '@/front/containers/DefaultLayout';
import { uaEventHandler } from '@/front/helpers/analytics/Ga';
import type { DataLayerPageData } from '@/front/hooks/usePageContext';
import usePageContext from '@/front/hooks/usePageContext';

export interface LayoutProps {
    header: React.ReactNode;
    banners: React.ReactNode;
    body: React.ReactNode;
    footer: React.ReactNode;
    pageProps?: unknown;
}

export interface BaseLayoutProps {
    gaPageData?: GaPageData;
    dataLayerPageData: DataLayerPageData;
    shouldSendCriteoPageView?: boolean;
    layout?: React.ComponentType<LayoutProps>;
    pageProps?: unknown;
    isConsentGranted: boolean;
}

const getDisplayFirstName = (firstName: User['firstName']) =>
    firstName !== '' ? firstName : translate('No name');

export const gaMenuHandler = (element: HTMLElement) => {
    if (element.dataset.uaEventAction) {
        uaEventHandler(element);
    }
};

const BaseLayout = ({
    children,
    gaPageData,
    dataLayerPageData,
    shouldSendCriteoPageView,
    layout,
    pageProps,
    isConsentGranted,
}: PropsWithChildren<BaseLayoutProps>) => {
    const { userState, dispatcher } = usePageContext({
        gaPageData,
        dataLayerPageData,
        shouldSendCriteoPageView,
        isConsentGranted,
    });

    const ThorProLinkZone: { [key: string]: string } = {
        NAVBAR_PRO_ITEM: 'header',
        NAVBAR_PRO_ITEM_FOOTER: 'bottom',
    };
    const eventData: ThorEventGA4Interface = {
        event: 'outlink',
        link_url: 'https://pro.meilleursagents.com/',
        outbound: true,
        av_zone: '',
    };
    const thorEvent = (event: string) => {
        eventData.av_zone = ThorProLinkZone[event];
        sendEventProLink();
    };
    const sendEventProLink = useThorEvent({ eventData });

    const userProTypeList: Array<UserProType> = ['agency', 'representative'];
    const shouldDisplayShowcaseBanner =
        userState.user &&
        !userState.user.hasProAccess &&
        userProTypeList.includes(userState.user.type);

    if (dispatcher?.sentry_www_next) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        Sentry.getCurrentHub().getClient().getOptions().sampleRate = 1;
    }

    const Layout = layout || DefaultLayout;

    return (
        <Layout
            banners={<>{shouldDisplayShowcaseBanner && <ShowcaseBanner />}</>}
            body={children}
            footer={<Footer onEvent={thorEvent} />}
            header={
                <Navbar
                    accountMenuOptions={{
                        isPro: Boolean(userState.user?.hasProAccess),
                        // TODO: use conditional value to show SaleProject
                        hasSaleProject: true,
                    }}
                    skipToContent="main-content"
                    userName={
                        userState.user ? getDisplayFirstName(userState.user.firstName) : undefined
                    }
                    onMenuOpen={gaMenuHandler}
                    onEvent={thorEvent}
                />
            }
            pageProps={pageProps}
        />
    );
};

export default BaseLayout;
