import { useEffect, useState } from 'react';
import { useConsentService } from '@aviv-csnt/react';
import { ThemeProvider } from '@emotion/react';
import { initTouchEvent } from '@ma-js-common/device-helpers';
import { translate } from '@ma-js-common/translate';
import { ReactComponentsConfig } from '@ma-react/components';
import { theme } from '@ma-react/styles';
import { WithThor } from '@sl/react-thor';
import type { AppProps as NextAppProps, NextWebVitalsMetric } from 'next/app';
import Head from 'next/head';
import Link from 'next/link';
import Script from 'next/script';
import { DefaultSeo } from 'next-seo';

import type { EventArgs, GaPageData } from '@/entities/types/Ga';
import HtmlMetas from '@/front/components/HtmlMetas';
import LoadingOverlay from '@/front/components/LoadingOverlay';
import type { BaseLayoutProps } from '@/front/containers/BaseLayout';
import BaseLayout from '@/front/containers/BaseLayout';
import { DispatcherProvider } from '@/front/context/DispatcherContext';
import { UserProvider } from '@/front/context/UserContext';
import { gaEvent } from '@/front/helpers/analytics/Ga';
import { initWa, updatePageviewId } from '@/front/helpers/analytics/Wa';
import type { DataLayerPageData } from '@/front/hooks/usePageContext';
import dataDomeScript from '@/front/scripts/dataDome';
import realytics from '@/front/scripts/realytics';
import { getPublicConfig } from '@/helpers/config/getConfig';
import { assetsCDNUrl } from '@/helpers/http/url';

import '@splidejs/react-splide/css';
import '@/helpers/yup/yupSetup';

import '@/front/styles/main.scss';

// getPublicConfig doesn't execute at build time
if (process.env.NEXT_PUBLIC_MOCK_SERVER === 'true') {
    require('@/config/msw');
}

export interface AppProps extends NextAppProps {
    Component: NextAppProps['Component'] & Pick<BaseLayoutProps, 'layout'>;
    pageProps: NextAppProps['pageProps'] & {
        gaPageData?: GaPageData;
        dataLayerPageData: DataLayerPageData;
        shouldSendCriteoPageView?: boolean;
    };
}

const App = ({
    Component,
    pageProps: { gaPageData, dataLayerPageData, shouldSendCriteoPageView, ...pageProps },
    router,
}: AppProps) => {
    const { isConsentGranted, consentItems } = useConsentService('gxmp7r7GKwtteD', {
        enableTCF: false,
    });

    const isRealyticsConsent = Object.values(consentItems).find(
        (item): item is { name: string; consent: boolean } => {
            return (item as { name: string }).name === 'Realytics';
        },
    )?.consent;

    if (isConsentGranted) {
        window.thor.onConsent();
    }

    useEffect(() => {
        // Remove the server-side injected CSS.
        const serverSideStyle = document.querySelector('#server-side-styles');
        if (serverSideStyle) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //@ts-ignore
            serverSideStyle.parentElement.removeChild(serverSideStyle);
        }

        initTouchEvent();
        initWa();
    }, []);

    const [isRouteChanging, setIsRouteChanging] = useState(false);

    useEffect(() => {
        const handleRouteChangeStart = () => setIsRouteChanging(true);
        const handleRouteChangeComplete = () => {
            updatePageviewId();
            setIsRouteChanging(false);
        };

        router.events.on('routeChangeStart', handleRouteChangeStart);
        router.events.on('routeChangeComplete', handleRouteChangeComplete);
        return () => {
            router.events.off('routeChangeStart', handleRouteChangeStart);
            router.events.off('routeChangeComplete', handleRouteChangeComplete);
        };
    }, [router.events, router.asPath]);

    const [locationPathname, locationSearch] = router.asPath.split('?');

    // This useEffect sets up an event listener on the skip-to-content link that, when clicked, will focus on the main content of the page.
    // This allows keyboard users to skip over navigation links and go straight to the main content.
    useEffect(() => {
        const handleClick = () => {
            const main = document.getElementById('main-content');
            main?.focus();
        };

        const skiplink = document.querySelector('.skip-to-content');
        skiplink?.addEventListener('click', handleClick);

        return () => {
            skiplink?.removeEventListener('click', handleClick);
        };
    }, []);

    return (
        <>
            <WithThor
                userConsent={false}
                cloudFront={false}
                compatibilities={{ GA3: false, GA4: true }}
            >
                <Head>
                    <HtmlMetas />
                </Head>

                <DefaultSeo
                    canonical={`https://www.meilleursagents.com/${locationPathname}`}
                    description={translate(
                        'Obtenez gratuitement une estimation en ligne du prix de votre bien immobilier, puis engagez les meilleures agences immobilières partenaires de MeilleursAgents.com pour vendre votre bien immobilier au meilleur prix.',
                    )}
                    openGraph={{
                        title: translate('Meilleursagents.com'),
                        description: translate(
                            'Obtenez gratuitement une estimation en ligne du prix de votre bien immobilier, puis engagez les meilleures agences immobilières partenaires de MeilleursAgents.com pour vendre votre bien immobilier au meilleur prix.',
                        ),
                        type: 'website',
                        url: 'https://www.meilleursagents.com/',
                        images: [
                            {
                                url: assetsCDNUrl(
                                    'common/browser-icons/202105/ms-icon-310x310.png',
                                ),
                                width: 310,
                                height: 310,
                                alt: translate('Logo Meilleurs Agents'),
                                type: 'image/png',
                            },
                        ],
                    }}
                    title={translate(
                        'MeilleursAgents : N°1 des prix immobilier et estimation en ligne',
                    )}
                />
                <ReactComponentsConfig
                    value={{
                        linkWrapper: { component: Link, type: 'next' },
                        routerLocation: {
                            pathname: locationPathname,
                            search: locationSearch,
                        },
                        // TODO: Check if we finally disable all static optimization
                        proUrl: 'https://pro.meilleursagents.com/',
                    }}
                >
                    <ThemeProvider theme={theme}>
                        <UserProvider>
                            <DispatcherProvider>
                                {isRouteChanging && <LoadingOverlay />}
                                <BaseLayout
                                    dataLayerPageData={dataLayerPageData}
                                    gaPageData={gaPageData}
                                    layout={Component.layout || undefined}
                                    pageProps={pageProps}
                                    shouldSendCriteoPageView={shouldSendCriteoPageView}
                                    isConsentGranted={isConsentGranted}
                                >
                                    <Component {...pageProps} />
                                </BaseLayout>
                            </DispatcherProvider>
                        </UserProvider>
                    </ThemeProvider>
                </ReactComponentsConfig>
                {isRealyticsConsent && <Script id="realytics">{realytics}</Script>}
                {isConsentGranted && (
                    <Script id="dataDome">
                        {dataDomeScript(getPublicConfig('DATADOME_CLIENT_KEY') as string)}
                    </Script>
                )}
            </WithThor>
        </>
    );
};

export function reportWebVitals({ id, name, label, value }: NextWebVitalsMetric) {
    const webVitalEvent: EventArgs = {
        category: label === 'web-vital' ? 'Web Vitals' : 'Next.js custom metric',
        action: name,
        eventValue: Math.round(name === 'CLS' ? value * 1000 : value),
        label: id,
        nonInteraction: true,
        transport: 'beacon',
    };
    if (!window.MA_WEBVITALS?.hasSendPageView) {
        window.MA_WEBVITALS = {
            hasSendPageView: false,
            gaEvents: [webVitalEvent, ...(window.MA_WEBVITALS?.gaEvents || [])],
        };
    } else {
        gaEvent(webVitalEvent);
    }
}

export default App;

declare global {
    interface Window {
        thor: {
            onConsent: () => void;
        };
    }
}
